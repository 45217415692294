<template>
  <el-form :inline="true" :model="searchFilter" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="商品类型">
      <el-select v-model="searchFilter.type" clearable>
        <el-option label="优惠券" :value="1" />
      </el-select>
    </el-form-item>
    <el-form-item label="关联对象">
      <el-input
        placeholder="请输入关联对象"
        v-model="searchFilter.typeName"
        clearable
        maxlength="20"
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="searchFilter.status" clearable>
        <el-option label="上架" :value="1" />
        <el-option label="下架" :value="0" />
      </el-select>
    </el-form-item>
    <el-form-item label="创建人">
      <el-input v-model="searchFilter.createdName" placeholder="请输入创建人" clearable></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">
        查询
      </el-button>
      <el-button @click="resetLists">
        重置
      </el-button>
      <el-button type="primary" @click="addPoints" v-if="data.authMenus.add"> 新增 </el-button>
      <el-button type="primary" v-if="data.authMenus.export" @click="exportData"> 导出 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column
      label="商城排序"
      align="center"
      type="index"
      width="100"
      :index="indexfn"
      resizable
    ></el-table-column>
    <el-table-column
      label="调整排序"
      width="200"
      resizable
      v-if="
        data.authMenus.sort &&
          !searchFilter.type &&
          !searchFilter.typeName &&
          searchFilter.status !== 0 &&
          searchFilter.status !== 1
      "
    >
      <template #default="scope">
        <el-button type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.update && !scope.row.isStart"
          >上移</el-button
        >
        <el-button type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update && !scope.row.isEnd"
          >下移</el-button
        >
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="code" width="200" label="可兑换商品id" align="center" resizable />
    <el-table-column show-overflow-tooltip prop="name" width="200" label="可兑换商品名称" align="center" resizable />
    <el-table-column show-overflow-tooltip prop="type" width="100" label="商品类型" align="center" resizable>
      <template #default="scope">
        {{ scope.row.type == 1 ? '优惠券' : '' }}
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="typeName" width="300" label="关联对象" align="center" resizable />
    <el-table-column show-overflow-tooltip prop="status" width="100" label="状态" align="center" resizable>
      <template #default="scope">
        <el-tag v-if="scope.row.status === 1">上架</el-tag>
        <el-tag v-else-if="scope.row.status === 0" type="error">下架</el-tag>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="amount" width="100" label="兑换积分" align="center" resizable />
    <el-table-column
      show-overflow-tooltip
      prop="userCustomerLevelName"
      width="200"
      label="可兑换会员等级"
      align="center"
      resizable
    >
    </el-table-column>
    <el-table-column
      show-overflow-tooltip
      prop="grantLimit"
      width="150"
      label="个人可兑换数量"
      align="center"
      resizable
    >
      <template #default="scope">
        {{ scope.row.grantLimit == -1 ? '不限制' : scope.row.grantLimit }}
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="grantCount" width="100" label="可兑换总数" align="center" resizable>
      <template #default="scope">
        {{ scope.row.grantCount == -1 ? '不限制' : scope.row.grantCount }}
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="issuedCount" width="100" label="已兑换数量" align="center" resizable />
    <el-table-column show-overflow-tooltip prop="isUsedCount" width="100" label="已使用数量" align="center" resizable />
    <el-table-column show-overflow-tooltip prop="createdName" width="100" label="创建人" align="center" resizable />
    <el-table-column show-overflow-tooltip prop="createdDate" width="200" label="创建时间" align="center" resizable />
    <el-table-column label="操作" width="100" align="center" fixed="right" resizable>
      <template #default="scope">
        <el-button type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.update">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="searchFilter.pageNum"
    v-model:page-size="searchFilter.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <addOrUpdate
    v-if="data.dialogVisible"
    @handleCloseDialog="updateDialogVisible"
    :updateRowMsg="data.updateRowMsg"
    :levels="data.levels"
  ></addOrUpdate>
</template>

<script setup>
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import API from '../service/api'
import { ref, reactive, onMounted, computed, onUnmounted } from 'vue'
import addOrUpdate from './AddOrUpdate.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'
import { createMoveBall } from '@/utils/createMoveBall.js'
import { post } from '@/lin/plugin/axios'
const searchFilter = reactive({
  type: '',
  typeName: '',
  status: '',
  pageSize: 10,
  pageNum: 1,
})

const data = reactive({
  authMenus: {},
  tableData: [],
  total: 10,
  dialogVisible: false,
  updateRowMsg: {},
  loading: false,
  levels: [],
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryData()
  searchLists()
})
const indexfn = computed(() => {
  return (searchFilter.pageNum - 1) * searchFilter.pageSize + 1
})

const queryData = async () => {
  let res = await API.queryLevels({
    pageSize: 10,
    pageNum: 1,
  })
  if (res.code === '200') {
    data.levels = res.data.records
  } else {
    ElMessage.error(res.message)
  }
}
const resetLists = () => {
  searchFilter.type = ''
  searchFilter.typeName = ''
  searchFilter.status = ''
  searchFilter.createdName = ''
  searchFilter.pageSize = 10
  searchLists()
}

const searchLists = () => {
  searchFilter.pageNum = 1
  queryLists()
}

const queryLists = () => {
  data.loading = true
  if (data.authMenus.query) {
    let queryParams = {
      pageNum: searchFilter.pageNum,
      pageSize: searchFilter.pageSize,
      criteria: JSON.stringify(searchFilter),
    }
    API.pointsGoodsQuery(queryParams)
      .then(res => {
        if (res.code === '200') {
          data.loading = false
          data.tableData =
            res.data.items?.map((item, index) => {
              return {
                ...item,
                userCustomerLevelName: JSON.parse(item.userCustomerLevel).map(a => a.name),
              }
            }) || []
          data.tableData[0].isStart = true
          data.tableData[data.tableData.length - 1].isEnd = true
          data.total = res.data.total
        } else {
          ElMessage.error(res.msg)
        }
      })
      .finally(() => (data.loading = false))
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//添加
const addPoints = () => {
  data.updateRowMsg = {
    coupons: {},
    userCustomerLevels: [],
    type: 1,
    status: 1,
    grantCountStatus: 0,
    grantLimitStatus: 0,
  }
  data.dialogVisible = true
}

const editRow = rowMsg => {
  data.updateRowMsg = {
    ...rowMsg,
    grantCountStatus: rowMsg.grantCount == -1 ? 1 : 0,
    grantLimitStatus: rowMsg.grantLimit == -1 ? 1 : 0,
    coupons: JSON.parse(rowMsg.typeValue),
    userCustomerLevels: JSON.parse(rowMsg.userCustomerLevel).map(a => a.code),
  }
  data.dialogVisible = true
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      editRow(rowMsg)
      break
    case 2:
      handleSort(rowMsg, 2)
      break
    case 3:
      handleSort(rowMsg, 3)
      break
  }
}

const handleSort = async (row, type) => {
  let list = [
    {
      code: row.code,
      sort: row.sort,
    },
  ]
  if (type == 2) {
    let index = data.tableData.findIndex(a => a.code == row.code)
    list.push({
      code: data.tableData[index + 1].code,
      sort: data.tableData[index + 1].sort,
    })
  } else {
    let index = data.tableData.findIndex(a => a.code == row.code)
    list.push({
      code: data.tableData[index - 1].code,
      sort: data.tableData[index - 1].sort,
    })
  }

  let res = await API.pointsGoodsUpdateSort(list)
  if (res.code == 200) {
    queryLists()
  } else {
    ElMessage.error(res.msg)
  }
}
let timer = ref('')
const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    timer.value = setTimeout(() => {
      queryLists()
    }, 1000)
  }
}
onUnmounted(() => {
  clearTimeout(timer.value)
})
//分页
const handleSizeChange = val => {
  searchFilter.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  searchFilter.pageNum = val
  queryLists()
}

const exportData = async event => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  data.loading = true
  let queryParams = {
    pageNum: searchFilter.pageNum,
    pageSize: searchFilter.pageSize,
    criteria: JSON.stringify(searchFilter),
  }
  createMoveBall(event)
  let res = await post(`/shadow/export/download?type=POINTS_GOODS`, queryParams).finally(() => {
    data.loading = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  justify-content: flex-end;

  .search-right {
    margin-right: 20px;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
